
import { Component, Vue } from "vue-property-decorator";
import LoadingButton from "@/components/LoadingButton.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LoadingButton,
  },
})
export default class WelcomePage extends Vue {
  @Getter("IdentityManagement/getConfirmationEmail") userEmail!: string;

  get userName(){
    const storageName = localStorage.getItem('nombre');

    if (storageName) {
      return storageName
    } 
    return null
  } 

  created() {
    //TODO: If not found in store look for URL Value
    if (!this.userEmail) {
      console.warn("There was not confirmation email in store");
      this.$router.push("/register");
    }
  }

  loginRedirect() {
    this.$router.push("/login");
  }
}
